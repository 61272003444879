import httpUtil from "@/utils/httpUtil";


/**获客文章获取 */
export const getDynamicInfo = async params => httpUtil.post("/company/getDynamicInfoPC", params);

/**文章发布状态 */
export const updateDynamicState = async params => httpUtil.post("/company/updateDynamicState", params);

/**发布文章 */
export const addDynamicInfo = async (params,type) => httpUtil.post("/company/addDynamicInfo", params,type);

/**删除文章 */
export const delDynamicInfo = async params => httpUtil.post("/company/delDynamicInfo", params);

/**员工数据 */
export const queryStaffData =  params => httpUtil.post("/customerEssay/queryStaffData", params);

/**获取员工列表 */
export const  queryCompanyStaff = params => httpUtil.post("/customerEssay/queryCompanyStaff", params);

/**访客 */
export const queryVisitorsData = params => httpUtil.post("/customerEssay/queryVisitorsData", params);


/**员工数据详情 */
export const queryStaffShareDetail = params => httpUtil.post("/customerEssay/queryStaffShareDetail", params);

/***获取文章详情 ****/
export const getDynamicDetail = async params => httpUtil.get("/company/detail", params);

export const queryAllType = params => httpUtil.post("/essayType/queryAllType", params);
